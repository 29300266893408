import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AppService } from 'src/app/services/AppService/app.service';
import { API } from 'src/app/common/scripts/constant';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private builder: FormBuilder, private appService: AppService, private router: Router, private toaster: ToastrService) {
  }

  ngOnInit() {
    localStorage.clear();
  }

  username = new FormControl('', [
    Validators.required,
    Validators.email
  ])

  password = new FormControl('', [
    Validators.required
  ])

  loginForm: FormGroup = this.builder.group({
    email: this.username,
    password: this.password
  })

  login(): any {
    if (this.loginForm.status == "VALID") {
      this.appService.CallAjaxUsingPostRequest(API.ADMIN_LOGIN_URL, this.loginForm.value).subscribe(
        data => {
          if (data.flag == 133) {
            localStorage.setItem('auth', JSON.stringify(data));
            this.router.navigate(['/admin'])
          }
          else {
            this.toaster.error(data.error, 'ERROR');
          }
        },
        err => {
          this.toaster.error(err, 'ERROR');
        }
      )
    }
    else {
      this.toaster.error("Please fill all fields.", 'ERROR');
    }
  }
}
