import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/AuthService/auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  private access_token;
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.access_token = (localStorage.getItem('auth') == 'undefined' || !localStorage.getItem('auth')) ? '' : JSON.parse(localStorage.getItem('auth'));
    this.access_token = this.access_token.access_token;

    if (req.method == "POST" && this.access_token) {
      const duplicate = req.clone({ body: { ...req.body, access_token: this.access_token } });
      return next.handle(duplicate).pipe(
        map(resp => {
          if (resp.type && this.access_token) {
            if (resp instanceof HttpResponse) {
              if (resp.body.flag == 101) {
                this.authService.loggedOut();
              }
            }
          }
          // Several HTTP events go through that Observable 
          // so we make sure that this is a HTTP response
          return resp;
        })
      );
    }
    return next.handle(req);
  }

  constructor(private authService: AuthService) {
  }
}
