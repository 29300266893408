
'use strict';

import { environment } from 'src/environments/environment';

export const API = {
    "ADMIN_LOGIN_URL": environment.API_URL + "admin/login",
    "DASHBOARD_COUNT_URL": environment.API_URL + "admin/dashboard_count",
    "USERS_LISTING_URL": environment.API_URL + "admin/user_list",
    "DRIVER_LISTING_URL": environment.API_URL + "admin/driver_list",
    "GROUP_DELETE_URL": environment.API_URL + "admin/delete_group",
    "GROUP_LISTING_URL": environment.API_URL + "admin/group_list",
    "POST_LISTING_URL": environment.API_URL + "admin/post_list",
    "COMMENT_LISTING_URL": environment.API_URL + "rider_points",
    "PAGE_LISTING_URL": environment.API_URL + "admin/page_list",
    "PAGE_DELETE_URL": environment.API_URL + "admin/delete_page",
    "POST_DELETE_URL": environment.API_URL + "admin/delete_post",
    "COMMENT_DELETE_URL": environment.API_URL + "admin/delete_comment",
    "COMMENTS_LISTING_URL": environment.API_URL + 'admin/fetch_comments'
};
