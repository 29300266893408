import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isValid;
  constructor(private router: Router) { }

  public isAuthenticated(): boolean {
    this.isValid = (localStorage.getItem('auth') == 'undefined' || !localStorage.getItem('auth')) ? '' : JSON.parse(localStorage.getItem('auth'));
    if (this.isValid.access_token) {
      return true;
    }
  }

  public loggedOut() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
