<div class="login-box">
  <div class="login-logo">
    <a href="javascript:void(0)"><b>Admin</b>QudosList</a>
  </div>
  <div class="login-box-body">
    <div id="Msg" class="alert" ng-class="Message.Class" ng-bind="Message.Text" ng-show="Message"></div>
    <form id="LoginForm" (ngSubmit)='login()' [formGroup]="loginForm">
      <div class="form-group has-feedback">
        <input type="email" name="email" class="form-control" placeholder="Email" [formControl]="username">
        <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
        <div [hidden]="username.valid || username.untouched" class="error">
          <div [hidden]="!username.hasError('email')">
            Username or email is not valid.
          </div>
          <div [hidden]="!username.hasError('required')">
            Username is required.
          </div>
        </div>
      </div>
      <div class="form-group has-feedback">
        <input type="password" name="password" class="form-control" placeholder="Password" [formControl]="password">
        <span class="glyphicon glyphicon-lock form-control-feedback"></span>
        <div [hidden]="password.valid || password.untouched" class="error">
          <div [hidden]="!password.hasError('required')">
            The password is required.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <button type="submit" class="btn btn-primary btn-block btn-flat">Sign In</button>
        </div>

      </div>
    </form>
  </div>
</div>